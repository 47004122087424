import React from 'react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { css } from '@emotion/core'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'

import 'react-day-picker/lib/style.css'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const DATE_FORMAT = 'ddd MMM D YYYY'

const containsHoliday = (start, end) => {
  const holiday = dayjs()
    .month(11)
    .date(28)
  return holiday.isSameOrAfter(start) && holiday.isSameOrBefore(end)
}

const initState = ({ start }) => {
  let end = start.clone().add(10, 'weeks')
  const holiday = containsHoliday(start, end)
  if (holiday) {
    end.add(1, 'weeks')
  }
  return {
    start,
    end,
    holiday,
  }
}

const reducer = (state, action) => {
  if (action.type === 'set_start_date') {
    const { start } = action
    let end = start.clone().add(10, 'weeks')
    const holiday = containsHoliday(start, end)
    if (holiday) {
      end.add(1, 'weeks')
    }
    return {
      start,
      end,
      holiday,
    }
  }
  return state
}

export default () => {
  const today = dayjs()
  const [state, dispatch] = React.useReducer(
    reducer,
    { start: today },
    initState
  )
  const { start, end, holiday } = state
  const message = holiday ? '(+1 week for holiday)' : ''

  const handleDayChange = day => {
    const newStart = dayjs(day)
    dispatch({ type: 'set_start_date', start: newStart })
  }

  return (
    <section css={tenclass}>
      <h2>10 Class Calculator</h2>
      <div className="label">Start Day:</div>
      <div className="date">
        <DayPickerInput
          value={start.format(DATE_FORMAT)}
          format={DATE_FORMAT}
          onDayChange={handleDayChange}
          formatDate={formatDate}
          parseDate={parseDate}
        />
      </div>
      <div className="label">10 Class Card Expiration:</div>
      <div className="date">
        {end.format(DATE_FORMAT)} {message}
      </div>
    </section>
  )
}

const tenclass = css`
  margin: 1rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  h2 {
    grid-column: 1 / -1;
    text-align: center;
  }

  .label {
    justify-self: end;
    padding-right: 1rem;
  }

  .date {
    justify-self: start;
    padding-left: 1rem;
  }
`

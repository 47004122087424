/** @jsx jsx */
// import React from "react"
import { jsx, Styled } from 'theme-ui'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Box, Heading, Link } from '../../components/ui'
import TenClass from '../../components/TenClass'

const TeachersPage = () => (
  <Layout>
    <SEO title="Teachers" />
    <Box variant="layout.section">
      <Heading>Teachers</Heading>
      <Styled.ul>
        <li>
          <Link to="/teachers/pinkfloor">Pink Floor Calendar</Link>
        </li>
      </Styled.ul>
      <TenClass />
    </Box>
  </Layout>
)

export default TeachersPage
